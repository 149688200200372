import ExpCommercialTheme from 'themes/expCommercial';
import { Tenant } from '../tenants';
import { ThemeConfig } from 'themes/themeConfig';
import { CountryCodeList } from 'types/countries';
import { SiteLanguage } from 'constants/locale';

export const EXP_COMMERCIAL_TENANT_ID = 'expCommercial' as const;
export const EXP_COMMERCIAL_L18N_NAMESPACE = 'expCommercial' as const;
export const EXP_COMMERCIAL_L18N_NAMESPACES = [EXP_COMMERCIAL_L18N_NAMESPACE, 'common'] as const;

// This is mostly template file, so values here needs to be updated when working on particular page

const headerConfig = {
  mainButtonCopy: `${EXP_COMMERCIAL_L18N_NAMESPACE}:header.mainButtonCopy`,
  authButtonCopy: `${EXP_COMMERCIAL_L18N_NAMESPACE}:header.authButtonCopy`,
  hasJoinExpPage: true,
  authMenuItems: [
    {
      label: `${EXP_COMMERCIAL_L18N_NAMESPACE}:header.authLinks.mySavedSearches`,
      link: '/account/saved/searches',
      id: 'my-saved-searches',
    },
    {
      label: `${EXP_COMMERCIAL_L18N_NAMESPACE}:header.authLinks.myFavourites`,
      link: '/account/saved/favourites',
      id: 'my-favourites',
    },
    {
      label: `${EXP_COMMERCIAL_L18N_NAMESPACE}:header.authLinks.editProfile`,
      link: '/account/details',
      id: 'edit-profile',
    },
    {
      label: `${EXP_COMMERCIAL_L18N_NAMESPACE}:header.authLinks.signOut`,
      id: 'sign-out',
      signOut: true,
    },
  ],
  menuItems: [
    {
      label: 'Services',
      link: 'https://www.expcommercial.com/services',
      id: 'services-button',
    },
    {
      label: 'Find an Expert',
      link: 'https://www.expcommercial.com/experts',
      id: 'find-expert-button',
    },
    {
      label: 'Listings',
      link: 'https://www.expcommercial.com/listings',
      id: 'listings-button',
    },
    {
      label: 'Join eXp',
      link: 'https://www.expcommercial.com/become-an-advisor',
      newTab: true,
      id: 'join-exp-button',
    },
    {
      label: 'About Us',
      link: 'https://www.expcommercial.com/about',
      id: 'about-button',
    },
    {
      label: 'Contact Us',
      link: 'https://www.expcommercial.com/contact-us',
      id: 'contact-button',
    },
  ],
};

const homeConfig = {
  heroImage: '/next/assets/images/commercial/hero.jpg',
  heroImageAlt: 'Commercial real estate hero image',
  carouselImages: [
    '/next/assets/images/carousel/exp-commercial-1.mp4',
    '/next/assets/images/carousel/exp-commercial-2.jpg',
    '/next/assets/images/carousel/exp-commercial-3.jpg',
  ],
  testimonialBlock: {
    backgroundImage: '/next/assets/images/agent-search/exp-mobile-banner-white-flag.jpg',
    heading: 'Hear From Our Commercial Experts',
    testimonials: [
      {
        image: '/next/assets/images/in-service/inservice-agent1.jpg',
        alt: 'Agent 1',
        name: 'Brittany Sims',
        company: 'eXp Realty, Virginia',
        text: '\"I\'m absolutely loving eXp. From the systems to the support, being able to do real estate while knowing I can build my vision is such an awesome feeling. Everything just feels very aligned.\"',
        key: 0,
      },
    ],
  },
  joinUsSection: {
    link: 'https://www.expcommercial.com/become-an-advisor',
    title: 'Why Join eXp Commercial',
    title2: 'Commercial Brokerage Program',
    description: 'When you join eXp Commercial, you become part of a global family of agents and partners dedicated to transforming the commercial real estate experience. Learn more about how we empower our agents with world-class education, strategic partnership and stock awards.',
    imageUrl: '/next/assets/images/join-us-commercial.jpg',
    buttonText: 'Learn More',
  },
  contentCards: {
    sectionTitleParts: ['Commercial', 'Real Estate', 'Experts'],
    cards: [
      {
        title: 'Commercial Services',
        image: '/next/assets/images/content-cards/commercial-services.jpg',
        titleColor: 'rgba(255, 255, 255, 0.8)',
        backgroundColor: '#163259',
        content: 'From office spaces to industrial properties, our dedicated commercial experts provide comprehensive services tailored to your business needs. We specialize in all types of commercial real estate transactions.',
        link: 'https://www.expcommercial.com/services',
        desktopTransform: 'rotate(-5deg) translateY(-1.5em)',
        tabletTransform: 'none',
        rounded: true,
        altText: 'Modern commercial building with glass facade.',
        buttonText: 'Explore Services',
      },
      {
        title: 'Commercial Listings',
        image: '/next/assets/images/content-cards/commercial-listings.jpg',
        titleColor: 'rgba(255, 255, 255, 0.7)',
        backgroundColor: '#6C82A0',
        content: 'Access a comprehensive database of commercial properties. Our platform provides detailed information about office spaces, retail locations, industrial properties, and more.',
        link: 'https://www.expcommercial.com/listings',
        tabletTransform: 'translateY(3em)',
        translateYTablet: '50',
        altText: 'Commercial property listing showcase',
        buttonText: 'View Listings',
      },
      {
        title: 'Expert Advisors',
        image: '/next/assets/images/content-cards/expert-advisors.jpg',
        titleColor: '#0C0F24',
        backgroundColor: '#DAE0E7',
        content: 'Work with experienced commercial real estate professionals who understand your business needs. Our advisors specialize in various commercial property types and market segments.',
        link: 'https://www.expcommercial.com/experts',
        desktopTransform: 'rotate(2deg) translateY(-1.5em)',
        tabletTransform: 'none',
        translateYDesktop: '-20',
        altText: 'Professional commercial real estate advisor in a meeting',
        buttonText: 'Find an Expert',
      },
      {
        title: 'Commercial Insights',
        image: '/next/assets/images/content-cards/commercial-insights.jpg',
        titleColor: '#0C0F24',
        backgroundColor: '#EEEEEE',
        content: 'Stay informed with the latest commercial real estate market trends, investment opportunities, and industry insights. Access valuable resources designed for commercial property investors and businesses.',
        link: 'https://www.expcommercial.com/insights',
        desktopTransform: 'rotate(7deg) translateY(2em)',
        tabletTransform: 'translateY(3em)',
        translateYDesktop: '40',
        translateYTablet: '50',
        rounded: true,
        altText: 'Commercial real estate market analysis',
        buttonText: 'Read Insights',
      },
    ],
  },
};

const footerConfig = {
  spotlightImage: 'image.png',
  links: {
    homeLink: 'expcommercial.com',
  },
  footerColumns: {
    column1: [
      {
        label: 'Services',
        link: 'https://www.expcommercial.com/services',
        isAnchor: false,
        target: '_blank',
      },
      {
        label: 'Find an Expert',
        link: 'https://www.expcommercial.com/experts',
        isAnchor: false,
        target: '_blank',
      },
      {
        label: 'Listings',
        link: 'https://www.expcommercial.com/listings',
        isAnchor: false,
        target: '_blank',
      },
      {
        label: 'Join eXp',
        link: 'https://www.expcommercial.com/become-an-advisor/',
        isAnchor: false,
        target: '_blank',
      },
      {
        label: 'Calendar',
        link: 'https://expcommercial.com/calendar/',
        isAnchor: false,
        target: '_blank',
      },
    ],
    column2: [
      {
        label: 'About Us',
        link: 'https://www.expcommercial.com/about',
        target: '_blank',
      },
      {
        label: 'Contact Us',
        link: 'https://www.expcommercial.com/contact-us',
        target: '_blank',
      },
      {
        label: 'Careers',
        link: 'https://expcommercial.com/contact-us',
        target: '_blank',
      },
      {
        label: 'eXp World Holdings',
        link: 'https://expcommercial.com',
        target: '_blank',
      },
    ],
    column3: [
      {
        label: 'Terms of Use',
        link: 'https://www.expcommercial.com/terms-of-service/',
        target: '_blank',
      },
      {
        label: 'Privacy Policy',
        link: 'https://www.expcommercial.com/privacy-policy/',
        target: '_blank',
      },
      {
        label: 'Cookie Policy',
        link: 'https://www.expcommercial.com/cookie-policy/',
        target: '_blank',
      },
      {
        label: 'DMCA Notice',
        link: 'https://www.expcommercial.com/dmca-notice/',
        target: '_blank',
      },
      {
        label: 'Privacy Rights Request',
        link: 'https://submit-irm.trustarc.com/services/validation/e6d7516d-df9c-4404-bd9f-21a2f0d2a6a4/',
        target: '_blank',
      },
    ],
    bottom: [
      [
        {
          label: 'Texas Real Estate Commission information about brokerage services',
          link: 'https://drive.google.com/file/d/1NMjX7L_BEHJWLQjUXnvf2Ivou4s8sTuA/view',
          isAnchor: true,
          target: '_blank',
        },
      ],
      [
        {
          label: 'Texas Real Estate Commission Consumer Protection Notice',
          link: 'https://www.trec.texas.gov/sites/default/files/pdf-forms/CN%201-4-1_1.pdf',
          isAnchor: true,
          target: '_blank',
        },
      ],
      [
        {
          label: 'eXp Realty® is committed to adhering to the guidelines of The New York State Fair Housing Regulations. To view The Fair Housing Notice',
        },
        {
          label: ' →',
          link: 'https://dos.ny.gov/system/files/documents/2021/08/fairhousingnotice.pdf',
          target: '_blank',
        },
        {
          label: ' Please click here',
          link: 'https://dos.ny.gov/fair-housing',
          isAnchor: true,
          target: '_blank',
        },
      ],
      [
        {
          label: '*Standardized Operating Procedure for Purchasers of Real Estate Pursuant to Real Property Law 442-H. To View',
        },
        {
          label: ' →',
          link: 'https://drive.google.com/file/d/1o1iG6CO5hFgawNGJibmd34loBFJeIqqJ/view',
          target: '_blank',
        },
        {
          label: ' Please click here',
          link: 'https://drive.google.com/file/d/1o1iG6CO5hFgawNGJibmd34loBFJeIqqJ/view',
          isAnchor: true,
          target: '_blank',
        },
      ],
      [
        {
          label: 'eXp Realty of California, Inc. | CA DRE# 01878277 | Deborah Penny – Designated Broker',
        },
      ],
      [
        {
          label: 'eXp Realty of Southern California, Inc. | CA DRE# 02187306 | Gina Saad – Designated Broker',
        },
      ],
      [
        {
          label: 'eXp Realty of Greater Los Angeles, Inc. | CA DRE# 01828532 | Justin DeCesare – Designated Broker',
        },
      ],
      [
        {
          label: 'eXp Realty of Northern California, Inc. | CA DRE# 01951343 | Ryan Rosas – Designated Broker',
        },
      ],
      [
        {
          label: 'This site is protected by reCAPTCHA and the Google ',
        },
        {
          label: ' Privacy Policy ',
          link: 'https://policies.google.com/privacy',
          target: '_blank',
          isAnchor: true,
        },
        {
          label: 'and ',
        },
        {
          label: ' Terms of Service ',
          link: 'https://policies.google.com/terms',
          target: '_blank',
          isAnchor: true,
        },
        {
          label: 'apply.',
        },
      ],
    ],
    joinUsLink: 'https://www.expcommercial.com/become-an-advisor',
    footerImageData: {
      src: '',
      alt: '',
    },
  },
  organization: {
    phoneNumber() {
      return '';
    },
    email() {
      return 'info@exprealty.net';
    },
    address() {
      return '';
    },
    twitterUrl: 'https://x.com/exprealty',
    facebookUrl: 'https://www.facebook.com/profile.php?id=100065096766753',
    instagramUrl: 'https://www.instagram.com/exp.commercial/',
    linkedinUrl: 'https://www.linkedin.com/company/exp-commercial/',
    youtubeUrl: 'https://www.youtube.com/channel/UCZ6dRCbKE_EErGssbZhaW0A',
    joinSectionUrl: 'https://www.expcommercial.com/become-an-advisor',
  },
  showAccessibilityLink: false,
};

const agentSearch = {
  headerImage: {
    mobileSrc: '/next/assets/images/agent-search/exp-mobile-banner-white-flag.jpg',
    src: '/next/assets/images/agent-search/exp-banner-white-flag.jpg',
    alt: 'Commercial real estate background',
  },
  agentTypeName: 'Commercial Expert',
  featuredAgentDescriptor: 'Commercial',
  pageTitle: 'Find Your Commercial Real Estate Expert',
};

const expCommercialTenant: Tenant<ThemeConfig> = {
  id: EXP_COMMERCIAL_TENANT_ID,
  theme: ExpCommercialTheme,
  localization: {
    languages: [SiteLanguage.English],
    countries: [CountryCodeList.UNITED_STATES],
    namespaces: EXP_COMMERCIAL_L18N_NAMESPACES,
  },
  config: {
    header: headerConfig,
    home: homeConfig,
    footer: footerConfig,
    agentSearch: agentSearch,
  },
  debug: {
    forceTenantHeaderValue: 'useTenant="useExpCommercial"',
  },
};

export default expCommercialTenant;