import expInServiceTenant, { EXP_IN_SERVICE_TENANT_ID } from './expInServiceTenant';
import { ThemeConfig } from 'themes/themeConfig';
import { Tenant } from './tenants';
import { ThemeNames as TenantNames } from 'types/themes';
import { CountryCodeList } from 'types/countries';
import expCommercialTenant, { EXP_COMMERCIAL_TENANT_ID } from './definitions/exp-commercial.tenant';

export function getTenantById(id: string): Tenant<ThemeConfig> | undefined {
  switch (id) {
  case EXP_IN_SERVICE_TENANT_ID:
    return expInServiceTenant;
  case EXP_COMMERCIAL_TENANT_ID:
    return expCommercialTenant;
  }
  return undefined;
}

export function getTenantByDebugHeaderValue(debugHeaderValue: string): Tenant<ThemeConfig> | undefined {
  if (debugHeaderValue === expInServiceTenant.debug.forceTenantHeaderValue) {
    return expInServiceTenant;
  }
  return undefined;
}

export function getContentPageHostFromTenant(tenantName?: TenantNames, siteCountryLocation?: string) {
  switch (tenantName) {
  case TenantNames.EXP_REALTY_CA:
    return 'https://exp-ca.content.zoocasa.com';
  case TenantNames.EXP_REALTY_US:
    return 'https://exp-us.content.zoocasa.com';
  case TenantNames.EXP_IN_SERVICE:
    return 'https://inservice.content.zoocasa.com';
  case TenantNames.EXP_COMMERCIAL:
    return 'https://exp-commercial.content.zoocasa.com';
  case TenantNames.ZOOCASA:
  default:
    return siteCountryLocation === CountryCodeList.UNITED_STATES ?
      'https://zoo-us.content.zoocasa.com' : 'https://zoo-ca.content.zoocasa.com';
  }
}
